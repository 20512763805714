import { useState } from 'react';
import { useSelector } from 'react-redux';
import FormLoader from '@activebrands/core-web/components/FormLoader';
import Gtm from '@activebrands/core-web/components/Gtm';
import { usePageLoad } from '@activebrands/core-web/hooks/lifecycle';
import { FEATURE_FLAGS } from '@activebrands/core-web/state/features/types';
import { getConnectionTimeout } from '@activebrands/core-web/utils/timings';

const ThirdPartyScripts = () => {
    const [render, setRender] = useState(false);
    const flags = useSelector(state => state.features);

    usePageLoad(() => {
        if (!render) {
            setTimeout(() => setRender(true), getConnectionTimeout());
        }
    });

    return (
        <>
            {/* Load essential third party scripts without delay */}
            {flags[FEATURE_FLAGS.GTM] && <Gtm />}
            {flags[FEATURE_FLAGS.FORMLOADER] && <FormLoader />}

            {/* Load all other third party scripts after page load*/}
            {/* {render ? <></> : null} */}
        </>
    );
};

export default ThirdPartyScripts;
